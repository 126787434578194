/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
// tslint:disable
import 'zone.js';
import '@angular/localize/init';
import 'core-js';
import { Buffer } from 'buffer';

// Run `npm install --save classlist.js`.

// @ts-ignore

(window as any).global = window;
global.Buffer = Buffer;
global.process = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  env: { DEBUG: undefined },
  version: '',
  nextTick: require('next-tick'),
} as any;
